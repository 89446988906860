/* desktop 769px — 1024px: Small screens, laptops */
@media only screen and (min-width: 769px) {
  .Dashboard_wrapper {
    .Dashboard_wrapper_inner__ {
      padding: 1rem;
      display: grid;
      gap: 2rem;
    }

    .banner__ {
      -webkit-box-shadow: 3px 3px 10px 3px #dddddd;
      -moz-box-shadow: 3px 3px 10px 3px #dddddd;
      box-shadow: 3px 3px 10px 3px #dddddd;
      padding: var(--btnPaddingType3);
      border-radius: var(--borderRadiusType1);

      .banner__inner {
        /* background: var(--colorRedDarkType1); */
        background: linear-gradient(
          -90deg,
          var(--colorRedDarkType1),
          var(--colorRedDarkType2)
        );
        color: var(--colorWhite);
        padding: var(--btnPaddingType3);
        border-radius: var(--borderRadiusType2);
      }

      .banner__inner__title {
        margin: 0;
        font-weight: 700;
        font-size: var(--textSize5);
        text-transform: capitalize;
      }

      .banner__inner__sub_title {
        padding: var(--btnPaddingType3);
        margin: 0;
        font-weight: 300;
        font-size: var(--textSize1);
      }
    }

    .row_grp__ {
      display: flex;
      gap: 2rem;
      align-items: flex-start;
    }

    .card_grp {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      gap: 1rem;
      height: 100%;

      .card__ {
        -webkit-box-shadow: 3px 3px 10px 3px #dddddd;
        -moz-box-shadow: 3px 3px 10px 3px #dddddd;
        box-shadow: 3px 3px 10px 3px #dddddd;
        border-radius: var(--borderRadiusType1);
        overflow: hidden;

        display: flex;
        flex-direction: column;
      }

      .card__inner {
        text-align: center;
        display: grid;
        height: 100%;
        /* flex-direction: column; */
        /* justify-content: space-between; */
        /* align-content: space-between; */
        /* gap: 1rem; */

        .card__inner__label {
          font-weight: 700;
          padding: var(--btnPaddingType3);
          font-size: var(--textSize5);
          color: var(--textDark);
        }

        .card__inner__title {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: var(--btnPaddingType3);
          /* border-top: var(--borderGreyDark); */
          /* background: var(--colorRedDarkType1); */
          color: var(--colorWhite);
          font-size: var(--textSize4);
        }

        .total {
          background: var(--colorOrangeType1);
        }
        .upcoming {
          background: var(--colorOrangeType1);
        }
        .completed {
          background: var(--bgGreen);
        }
        .cancelled {
          background: var(--textDark);
        }
        .missed {
          background: var(--bgRed);
        }
        .pending {
          background: var(--colorBlueDarkType1);
        }
      }
    }

    .card_grp2 {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
      gap: 2rem;

      .card__ {
        -webkit-box-shadow: 3px 3px 10px 3px #dddddd;
        -moz-box-shadow: 3px 3px 10px 3px #dddddd;
        box-shadow: 3px 3px 10px 3px #dddddd;
        border-radius: var(--borderRadiusType1);
        overflow: hidden;
        display: flex;
        /* align-items: center; */
      }

      .card__inner {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .card__inner__label {
          font-weight: 700;
          padding: var(--btnPaddingType3);
          font-size: var(--textSize5);
          min-width: 30%;
          text-align: center;
          justify-content: center;
          color: var(--textDark);
          height: 100%;
          display: flex;
          align-items: center;
        }

        .card__inner__title {
          display: flex;
          align-items: center;
          flex: 1;
          width: 100%;
          height: 100%;
          padding: var(--btnPaddingType3);
          /* border-top: var(--borderGreyDark); */
          background: var(--textDark);
          color: var(--colorWhite);
          /* font-size: var(--textSize1); */
        }
      }
    }

    .calendar_wrapper {
      width: 30%;
      /* border: var(--borderGreyDark); */
      border-radius: var(--borderRadiusType2);
      overflow: hidden;

      -webkit-box-shadow: 3px 3px 10px 3px #dddddd;
      -moz-box-shadow: 3px 3px 10px 3px #dddddd;
      box-shadow: 3px 3px 10px 3px #dddddd;
      border-radius: var(--borderRadiusType1);
      overflow: hidden;
      position: relative;

      .react-calendar {
        border: none !important;
        /* background: var(--colorRedLightType1); */
        /* color: white !important; */
      }

      .tile-upcoming {
        background-color: var(--bgGreen);
        color: white;
      }

      .tile-cancelled {
        /* background-color: lightcoral; */
      }

      .tile-completed {
        /* background-color: lightblue; */
      }

      .appointment-count {
        /* position: absolute; */
        width: 30px;
        height: 30px;

        margin: auto;

        background-color: rgba(0, 0, 0, 0.1);
        background: white;
        color: var(--textDark);
        font-size: 16px !important;

        border-radius: 50%;
        padding: 2px 5px;
        font-size: 0.8em;

        display: grid;
        place-content: center;
      }
    }

    .appointments_list {
      /* border: 1px solid grey; */
      /* padding: 1rem; */
      min-width: 30%;
      max-width: 30%;

      position: relative;
      display: grid;
      gap: 0.5rem;
      text-transform: capitalize;

      h2 {
        position: sticky;
        top: 0px;
        font-weight: 500;
        color: var(--colorPurpleLightType2);
        font-size: var(--textSize2);
        text-align: center;
      }

      .appointment_item_grp__ {
        /* border: 1px solid var(--textGrey); */
        /* box-shadow: 0 0 4px  var(--bgGreyDark); */
        border-radius: var(--borderRadiusType1);
        height: 300px;
        padding: var(--paddingType2);
        font-size: var(--textSize4);

        overflow-y: scroll;

        display: grid;
        gap: 1rem;

        /* This will change the scroll bar for the whole page */
        &::-webkit-scrollbar {
          width: 10px; /* width of the entire scrollbar */
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1; /* color of the tracking area */
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: #888; /* color of the scroll thumb */
          background: transparent;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555; /* color of the scroll thumb on hover */
          background: transparent;
        }
      }

      .appointment_item {
        font-weight: 400;
        text-transform: capitalize;

        strong {
          color: var(--colorEmphasisType1);
        }
      }
    }
  }
}

/*  tablet 481px — 768px: iPads, Tablets*/
@media only screen and (min-width: 481px) and (max-width: 768px) {
}

/* mobile 320px — 480px: Mobile devices*/
@media only screen and (max-width: 480px) {
  .Dashboard_wrapper {
    .Dashboard_wrapper_inner__ {
      padding: 1rem;
      display: grid;
      gap: 2rem;
    }

    .banner__ {
      -webkit-box-shadow: 3px 3px 10px 3px #dddddd;
      -moz-box-shadow: 3px 3px 10px 3px #dddddd;
      box-shadow: 3px 3px 10px 3px #dddddd;
      padding: var(--btnPaddingType3);
      border-radius: var(--borderRadiusType1);

      .banner__inner {
        /* background: var(--colorRedDarkType1); */
        background: linear-gradient(
          -90deg,
          var(--colorRedDarkType1),
          var(--colorRedDarkType2)
        );
        color: var(--colorWhite);
        padding: var(--btnPaddingType3);
        border-radius: var(--borderRadiusType2);
      }

      .banner__inner__title {
        margin: 0;
        font-weight: 700;
        font-size: var(--textSize5);
        text-transform: capitalize;
      }

      .banner__inner__sub_title {
        padding: var(--btnPaddingType3);
        margin: 0;
        font-weight: 300;
        font-size: var(--textSize1);
      }
    }

    .row_grp__ {
      /* display: flex; */
      /* gap: 2rem; */
      /* align-items: flex-start; */
    }

    .card_grp {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
      align-items: flex-start;
      gap: 1rem;
      /* height: 100%; */

      .card__ {
        -webkit-box-shadow: 3px 3px 10px 3px #dddddd;
        -moz-box-shadow: 3px 3px 10px 3px #dddddd;
        box-shadow: 3px 3px 10px 3px #dddddd;
        border-radius: var(--borderRadiusType1);
        overflow: hidden;

        /* display: flex; */
        /* flex-direction: column; */
      }

      .card__inner {
        text-align: center;
        display: grid;
        /* height: 100%; */
        /* flex-direction: column; */
        /* justify-content: space-between; */
        /* align-content: space-between; */
        /* gap: 1rem; */

        .card__inner__label {
          font-weight: 700;
          padding: var(--btnPaddingType3);
          font-size: var(--textSize5);
          color: var(--textDark);
        }

        .card__inner__title {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: var(--btnPaddingType3);
          /* border-top: var(--borderGreyDark); */
          /* background: var(--colorRedDarkType1); */
          color: var(--colorWhite);
          font-size: var(--textSize4);
        }

        .total {
          background: var(--colorOrangeType1);
        }
        .upcoming {
          background: var(--colorOrangeType1);
        }
        .completed {
          background: var(--bgGreen);
        }
        .cancelled {
          background: var(--textDark);
        }
        .missed {
          background: var(--bgRed);
        }
        .pending {
          background: var(--colorBlueDarkType1);
        }
      }
    }

    .card_grp2 {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      gap: 1rem;

      .card__ {
        -webkit-box-shadow: 3px 3px 10px 3px #dddddd;
        -moz-box-shadow: 3px 3px 10px 3px #dddddd;
        box-shadow: 3px 3px 10px 3px #dddddd;
        border-radius: var(--borderRadiusType1);
        overflow: hidden;
        display: flex;
        /* align-items: center; */
      }

      .card__inner {
        text-align: center;
        display: grid;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .card__inner__label {
          font-weight: 700;
          padding: var(--btnPaddingType3);
          font-size: var(--textSize5);
          min-width: 30%;
          text-align: center;
          justify-content: center;
          color: var(--textDark);
          height: 100%;
          display: flex;
          align-items: center;
        }

        .card__inner__title {
          display: flex;
          align-items: center;
          flex: 1;
          width: 100%;
          height: 100%;
          padding: var(--btnPaddingType3);
          /* border-top: var(--borderGreyDark); */
          background: var(--textDark);
          color: var(--colorWhite);
          /* font-size: var(--textSize1); */
        }
      }
    }

    .calendar_wrapper {
      /* width: 30%; */
      /* border: var(--borderGreyDark); */
      border-radius: var(--borderRadiusType2);
      overflow: hidden;

      -webkit-box-shadow: 3px 3px 10px 3px #dddddd;
      -moz-box-shadow: 3px 3px 10px 3px #dddddd;
      box-shadow: 3px 3px 10px 3px #dddddd;
      border-radius: var(--borderRadiusType1);
      overflow: hidden;
      position: relative;

      .react-calendar {
        border: none !important;
        /* background: var(--colorRedLightType1); */
        /* color: white !important; */
      }

      .tile-upcoming {
        background-color: var(--bgGreen);
        color: white;
      }

      .tile-cancelled {
        /* background-color: lightcoral; */
      }

      .tile-completed {
        /* background-color: lightblue; */
      }

      .appointment-count {
        /* position: absolute; */
        width: 30px;
        height: 30px;

        margin: auto;

        background-color: rgba(0, 0, 0, 0.1);
        background: white;
        color: var(--textDark);
        font-size: 16px !important;

        border-radius: 50%;
        padding: 2px 5px;
        font-size: 0.8em;

        display: grid;
        place-content: center;
      }
    }

    .appointments_list {
      /* border: 1px solid grey; */
      /* padding: 1rem; */
      /* min-width: 30%; */
      /* max-width: 30%; */

      position: relative;
      display: grid;
      gap: 0.5rem;
      text-transform: capitalize;

      h2 {
        position: sticky;
        top: 0px;
        font-weight: 500;
        color: var(--colorPurpleLightType2);
        font-size: var(--textSize2);
        text-align: center;
      }

      .appointment_item_grp__ {
        /* border: 1px solid var(--textGrey); */
        /* box-shadow: 0 0 4px  var(--bgGreyDark); */
        border-radius: var(--borderRadiusType1);
        height: 300px;
        padding: var(--paddingType2);
        font-size: var(--textSize4);

        overflow-y: scroll;

        display: grid;
        gap: 1rem;

        /* This will change the scroll bar for the whole page */
        &::-webkit-scrollbar {
          width: 10px; /* width of the entire scrollbar */
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1; /* color of the tracking area */
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: #888; /* color of the scroll thumb */
          background: transparent;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555; /* color of the scroll thumb on hover */
          background: transparent;
        }
      }

      .appointment_item {
        font-weight: 400;
        text-transform: capitalize;

        strong {
          color: var(--colorEmphasisType1);
        }
      }
    }
  }
}
