.profile_wrapper {
  .profile_wrapper_inner__ {
    /* background: var(--colorWhite); */
    /* border: var(--borderGreyDark); */
    border-radius: var(--borderRadiusType1);
    /* margin: auto; */
    /* padding: var(--btnPaddingType3); */
    display: flex;
    align-items: flex-start;
    /* width: 90%; */
    padding: 1rem;
    gap: 1rem;
    position: relative;
  }

  .profile_wrapper_inner__left {
    position: sticky;
    top: 12%;
    /* border: var(--borderGreyDark); */
    display: grid;
    gap: 1rem;
    background: var(--colorWhite);
    padding: var(--btnPaddingType1);
    border-radius: var(--borderRadiusType1);
    width: 25%;
    justify-content: center;

    -webkit-box-shadow: 3px 3px 10px 3px #dddddd;
    -moz-box-shadow: 3px 3px 10px 3px #dddddd;
    box-shadow: 3px 3px 10px 3px #dddddd;
  }

  .profile_wrapper_inner__left__top {
    position: relative;
    .change_avatar_btn {
      position: absolute;
      right: 0px;
      bottom: 0px;
      z-index: 6666;
    }
  }

  .profile_wrapper_inner__left__top__img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;
  }

  .verified_btn {
    background: var(--bgGreen);
    color: var(--colorWhite);
    cursor: none;
    &:hover {
      background: var(--bgGreen);
      color: var(--colorWhite);
    }
  }

  .not_verified_btn {
    background: var(--colorRedDarkType1);
    color: var(--colorWhite);
    cursor: none;
    &:hover {
      background: var(--colorRedDarkType1);
      color: var(--colorWhite);
    }
  }

  .profile_wrapper_inner__left__bottom {
    display: grid;
    gap: 0.5rem;
    /* place-content: center; */
    place-items: center;
    border-top: var(--borderGreyLight);
    padding-top: 1rem;
  }

  .profile_wrapper_inner__right {
    flex: 1;
    display: grid;
    gap: 1rem;

    .profile_wrapper_inner__right__top {
      /* border: var(--borderGreyDark); */
      /* background: url(../../../../Assets/patterns/R.jpeg); */
      background-size: contain;
      background-repeat: repeat-x;
      /* height: 156px; */
      border-radius: var(--borderRadiusType1);
      display: none;
    }

    .profile_wrapper_inner__right__controls {
      display: flex;
      justify-content: space-between;
    }

    .profile_wrapper_inner__right__bottom {
      border: var(--borderGreyLight);
      display: grid;
      gap: 1rem;
    }

    .section__ {
      display: grid;
      /* gap: 0.5rem; */
      padding: var(--btnPaddingType1);
      background: var(--colorWhite);
      border-radius: var(--borderRadiusType1);
      /* -webkit-box-shadow: 3px 3px 10px 3px #dddddd; */
      /* -moz-box-shadow: 3px 3px 10px 3px #dddddd; */
      /* box-shadow: 3px 3px 10px 3px #dddddd; */
      /* z-index: 2222; */

      .section__title {
        font-size: var(--textSize2);
        color: var(--colorYellowLightType2);
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: space-between;

        /* &:hover {
            background: var(--colorRedLightType1);
          } */
      }

      .section__content {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 1rem;

        .section__content__item {
          display: grid;
          gap: 0.2rem;

          .section__content__item__label {
            color: var(--textGrey);
            font-weight: 400;
            font-size: var(--textSize4);
            font-style: oblique;
          }
          .section__content__item__value {
            /* font-size: var(--textSize2); */
            /* font-weight: 400; */

            .input_field {
              padding: 8px 12px;
              width: 100%;
              border-radius: 0.35rem;
              border: var(--borderGreyDark);

              &::placeholder {
                color: var(--textGrey);
                font-weight: 400;
              }
            }

            .availability_table {
              width: 100%;

              tbody tr {
                width: 100%;
              }

              td {
                padding: var(--btnPaddingType3);
              }
            }
          }
        }
      }
    }

    .ratings__grp__container {
      display: grid;
      gap: 1rem;

      .ratings__member {
        /* background: var(--colorGreenLightType1); */
        border: var(--borderGreyLight);
        border-radius: var(--borderRadiusType1);
        padding: var(--btnPaddingType1);
        display: flex;
        align-items: center;
        gap: 1rem;

        .body_container__ {
          display: grid;
          gap: 0.5rem;
          width: 100%;
        }

        .name__ {
          color: var(--colorYellowLightType2);
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .time_stamp__ {
          font-size: var(--textSize4);
          font-weight: 400;
          color: var(--textGrey);
        }

        .comment__ {
          font-style: oblique;
        }
      }
    }

    .save_changes_box {
      padding: 2rem 0;
      display: flex;
      justify-content: center;

      .edit_profile_btn {
        background: var(--colorGreyType1);
        &:hover {
          background: var(--colorGreyType2);
        }
      }
    }
  }
}
