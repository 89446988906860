/* desktop 769px — 1024px: Small screens, laptops */
@media only screen and (min-width: 769px) {
  .DoctorsDashboard_wrapper {
    display: flex;
    height: 100vh;
    background: var(--colorWhite);
    /* background: var(--colorEmphasisType1); */

    overflow-y: hidden;

    .sidebar_wrapper {
      position: relative;
      width: 300px;
      /* border-right: var(--borderGreyLight); */

      .inner__ {
        /* position: sticky; */
        /* top: 70px; */
        display: grid;
        /* place-items: center; */
        gap: 1rem;
        padding: 1rem;
      }

      .box_1 {
        /* border: var(--borderGreyDark); */
        text-align: center;
        display: grid;
        place-items: center;
        gap: 0.25rem;
        /* border: var(--borderGreyLight); */
        border-radius: var(--borderRadiusType2);
        padding: var(--btnPaddingType1);
        /* background: var(--colorRedLightType1); */
        /* background: var(--colorEmphasisType2); */

        .image_box {
          width: 80px;
          height: 80px;
          position: relative;
          overflow: hidden;
          border-radius: 50%;

          img {
            width: 100%;
          }

          .change_avatar_btn {
            position: absolute;
            right: 0;
            bottom: 1%;
            z-index: 55555;
            background: var(--colorWhiteType1) !important;
            color: var(--colorYellowType1);
          }
        }

        h1 {
          margin: 0;
          font-weight: 500;
          text-transform: uppercase;
          font-size: var(--textSize3);
        }

        p {
          margin: 0;
          color: var(--bgGreyDark);
          font-size: var(--textSize4);
        }

        .nav_link {
          text-decoration: none;
          color: var(--textDark);
          font-size: var(--textSize3);
          padding: var(--btnPaddingType3);
          border-radius: var(--borderRadiusType2);
          display: flex;
          align-items: center;
          gap: 0.5rem;

          &:hover {
            background: var(--bgGreyLight);
          }
        }
      }

      .box_2 {
        text-align: center;
        border: var(--borderGreyLight);
        border-radius: var(--borderRadiusType2);
        padding: var(--btnPaddingType1);
        /* background: var(--colorBlueLightType1); */

        h3 {
          margin: 0;
          font-weight: 500;
          /* text-transform: uppercase; */
          font-size: var(--textSize3);
        }
        h1 {
          margin: 0;
          font-weight: 400;
          text-transform: uppercase;
          font-size: var(--textSize1);
        }
      }

      .box_3 {
        display: grid;
        border: var(--borderGreyLight);
        border-radius: var(--borderRadiusType2);
        padding: var(--btnPaddingType3);
        /* background: var(--colorYellowLightType1); */

        .nav_link {
          text-decoration: none;
          color: var(--textDark);
          /* color: var(--colorWhite); */
          font-size: var(--textSize3);
          padding: var(--btnPaddingType1);
          border-radius: var(--borderRadiusType2);
          display: flex;
          align-items: center;
          gap: 1rem;

          &:hover {
            color: var(--textDark);
            background: var(--bgGreyLight);
            /* background: var(--colorWhite); */
          }
        }
      }

      .box_4 {
        display: grid;
        border: var(--borderGreyLight);
        border-radius: var(--borderRadiusType2);
        padding: var(--btnPaddingType3);

        &:hover > img {
          filter: grayscale(0);
          scale: 1.2;
        }

        img {
          width: 120px;
          margin: auto;
          /* filter: grayscale(); */
          transition: all 0.3s ease-in-out;

          &:hover {
            filter: grayscale(0);
            scale: 1.2;
          }
        }

        .nav_link {
          text-decoration: none;
          color: var(--textDark);
          font-size: var(--textSize3);
          padding: var(--btnPaddingType1);
          border-radius: var(--borderRadiusType2);
          display: flex;
          align-items: center;
          gap: 1rem;

          &:hover {
            background: var(--bgGreyLight);
          }
        }
      }

      .box_5 {
        display: grid;
        border: var(--borderGreyLight);
        border-radius: var(--borderRadiusType2);
        padding: var(--btnPaddingType3);
        background: var(--colorRedLightType1);

        .logout_btn {
          text-decoration: none;
          color: var(--textDark);
          font-size: var(--textSize3);
          padding: var(--btnPaddingType1);
          border-radius: var(--borderRadiusType2);
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          background: var(--bgRed);
          color: var(--colorWhite);

          &:hover {
            background: var(--bgRedLight);
          }
        }
      }
    }

    .sidebar_wrapper_closed {
      width: 0px;
      transition: width 0.3s ease-in-out;
      opacity: 0;
      /* display: none; */
    }

    .sidebar_wrapper_opned {
      width: 300px;

      transition: width 0.3s ease-in-out;
    }

    .main_wrapper {
      z-index: 0;
      flex: 1;
      /* background: var(--bgGreyLight); */
      background: var(--colorWhite);
      position: relative;
      /* padding: 0 16px; */
      /* background: red; */
      height: 100vh;
      overflow-y: hidden;

      .fixed_bar {
        position: sticky;
        /* top: 0px; */
        display: grid;
        /* border-bottom: var(--borderGreyDark); */
        /* background: var(--bgGreyLight); */
        /* background: var(--colorWhite); */

        z-index: 999999;

        .row_1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .row_2 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 0.5rem;

          h1,
          h3 {
            margin: 0;
            font-weight: 500;
            text-transform: uppercase;
            font-size: var(--textSize1);
            color: var(--colorBlueDarkType2);
          }

          h3 {
            margin: 0;
            font-weight: 500;
            text-transform: uppercase;
            font-size: var(--textSize2);
          }
        }
      }

      .movable_area {
        /* background: rgb(209, 227, 91); */
        height: 95vh;
        /* background: var(--bgGreyLight); */
        background: var(--bgGreyLight);

        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 5px;
          border-radius: 5px;
          background: none;
        }

        &::-webkit-scrollbar-track {
          /* background: #f1f1f1; */
          background: none;
          width: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
          /* background: none; */
          width: 5px;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
          width: 5px;
        }
      }
    }

    .general_frame_wrapper {
      /* padding: 24px 0; */
      position: relative;

      .inner__ {
        display: grid;
        gap: 1rem;
        position: relative;
      }

      .heading_bar {
        position: sticky;
        top: 0px;
        transition: all 0.3s ease-in-out;
        display: grid;
        /* background: var(--bgGreyLight); */
        background: var(--colorWhite);
        color: var(--bgRed);
        z-index: 999999;
      }

      .heading_bar_2 {
        position: sticky;
        top: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* background: var(--bgGreyLight); */
        background: var(--colorWhite);
        z-index: 500;
        transition: all 0.3s ease-in-out;
        font-size: 14px;
        color: var(--bgRed);
      }

      .filter_bar {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        /* position: sticky;
      top: 100px; */
      }

      .grid_grp {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        gap: 1rem;
        padding: 0 1rem;
      }

      .grid_item {
        display: flex;
        border: var(--borderGrey);
        border-radius: var(--borderRadiusType1);
        background: var(--bgWhite);
        padding: var(--btnPaddingType3);
        align-items: center;
        justify-content: space-between;

        -webkit-box-shadow: 3px 3px 10px 3px #dddddd;
        -moz-box-shadow: 3px 3px 10px 3px #dddddd;
        box-shadow: 3px 3px 10px 3px #dddddd;

        .info_box {
          display: grid;
          gap: 0.5rem;
          /* flex: 1; */

          .date_time {
            /* display: grid; */
            display: flex;
            gap: 0.5rem;

            .date {
              font-size: var(--textSize4);
              background: var(--bgGreyLight);
              border-radius: var(--borderRadiusType2);
              padding: var(--btnPaddingType3);
              justify-self: flex-start;
            }
            .time {
              font-size: var(--textSize4);
              background: var(--bgGreyLight);
              border-radius: var(--borderRadiusType2);
              padding: var(--btnPaddingType3);
              justify-self: flex-start;
            }
          }

          .name {
            font-size: var(--textSize1);
            font-weight: 500;
          }

          .condition {
            /* font-weight: 500; */
            font-size: var(--textSize3);
            /* color: var(--textGrey); */
            /* background: #00ff6a; */
          }

          .controls_box {
          }
        }

        .image_box {
          border: var(--borderGreyLight);
          width: 150px;
          height: 150px;
          overflow: hidden;
          display: grid;
          /* place-items: center; */
          /* align-content: center; */
          place-content: center;
          border-radius: var(--borderRadiusType1);

          img {
            width: 100%;
          }
        }
      }

      .view_appointment_wrapper {
        display: flex;
        gap: 1rem;
        position: relative;

        .block_1 {
          width: 700px;
          /* border: var(--borderGrey); */
          /* border-radius: var(--borderRadiusType1); */
          /* background: var(--bgWhite); */
          padding: var(--btnPaddingType3);
          display: grid;
          gap: 2rem;
        }

        .block_child {
          border: var(--borderGrey);
          border-radius: var(--borderRadiusType1);
          padding: var(--btnPaddingType3);

          -webkit-box-shadow: 3px 3px 10px 3px #dddddd;
          -moz-box-shadow: 3px 3px 10px 3px #dddddd;
          box-shadow: 3px 3px 10px 3px #dddddd;
        }

        .block_head {
          font-weight: 600;
          margin-top: 0;
        }

        .patient_details_body {
          background: var(--bgWhite);
          border-radius: var(--borderRadiusType1);
          display: flex;

          .left_ {
            width: 250px;
            display: grid;
            place-content: center;
            text-align: center;
            padding: 1rem;
            gap: 0.5rem;

            .image_box {
              width: 150px;
              height: 150px;
              overflow: hidden;
              border-radius: 50%;
              display: grid;
              place-content: center;
              place-items: center;
              /* border: var(--borderGreyDark); */
              margin: auto;

              img {
                width: 100%;
              }
            }

            .name_box {
              margin: 0;
              font-weight: 500;
              text-transform: uppercase;
              font-size: var(--textSize2);
            }

            .specialization_box {
              margin: 0;
              color: var(--bgGreyDark);
              font-size: var(--textSize4);
            }

            .btn_grp {
              display: grid;
              gap: 0.5rem;
            }
            .nav_link {
              text-decoration: none;
              color: var(--textDark);
              font-size: var(--textSize3);
              padding: var(--btnPaddingType3);
              border-radius: var(--borderRadiusType2);
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 0.5rem;
              text-align: center;

              &:hover {
                background: var(--bgGreyLight);
              }
            }

            .start_consultation_btn {
              text-decoration: none;
              font-size: var(--textSize3);
              padding: var(--btnPaddingType3);
              border-radius: var(--borderRadiusType2);
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;
              gap: 0.5rem;
              text-align: center;
              background: linear-gradient(var(--bgGreen), var(--bgGreenLight));
              color: var(--bgWhite);
              font-weight: 600;
              cursor: pointer;

              &:hover {
                background: linear-gradient(
                  var(--bgGreenLight),
                  var(--bgGreen)
                );
                background: var(--bgGreenLight);
                /* color: var(--bgWhite); */
              }
            }

            .end_consultation_btn {
              text-decoration: none;
              font-size: var(--textSize3);
              padding: var(--btnPaddingType3);
              border-radius: var(--borderRadiusType2);
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;
              gap: 0.5rem;
              text-align: center;
              background: linear-gradient(var(--bgRed), var(--bgRedLight));
              color: var(--bgWhite);
              font-weight: 600;
              cursor: pointer;

              &:hover {
                background: linear-gradient(var(--bgRedLight), var(--bgGreen));
                background: var(--bgRedLight);
                /* color: var(--bgWhite); */
              }
            }
          }

          .right_ {
            flex: 1;
            border-left: var(--borderGrey);
          }

          .card_grp {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            gap: 1rem;
            padding: var(--btnPaddingType3);
            text-transform: capitalize;

            .card_item {
              .label {
                font-size: var(--textSize3);
                font-weight: 600;
              }
              .value {
                font-size: var(--textSize4);
                margin-top: 0.5rem;
              }
            }
          }
        }

        .patient_notes_body {
          background: var(--bgWhite);
          border-radius: var(--borderRadiusType1);
          padding: var(--btnPaddingType3);
          background: white;
        }

        .patient_submitted_files_body {
          .card_grp {
            display: grid;
            gap: 1rem;

            .card_item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              background: var(--bgWhite);
              border-radius: var(--borderRadiusType1);
              padding: var(--btnPaddingType1);

              .label {
                width: 80%;
              }

              .download {
              }
            }
          }
        }

        .drug_prescriptions {
          display: grid;
          gap: 1rem;
        }

        .drug_prescription_body {
          .card_grp {
            display: grid;
            gap: 1rem;

            .card_item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              background: var(--bgWhite);
              border-radius: var(--borderRadiusType1);
              padding: var(--btnPaddingType1);

              .label {
                width: 80%;
              }

              .download {
              }
            }
          }
        }

        .add_prescription_container {
          display: grid;
          place-content: center;
        }

        .block_2 {
          flex: 1;
          border: var(--borderGrey);
          border-radius: var(--borderRadiusType1);
          /* background: var(--bgWhite); */
          padding: var(--btnPaddingType3);
          position: relative;
          /* align-self: flex-start; */
          /* height: 70vh; */

          .sticky_inner__ {
            position: sticky;
            top: 100px;
            /* background: red; */
          }

          .btn_grp {
            display: grid;
            gap: 0.5rem;
            /* position: sticky;
          top: 150px; */
          }

          .nav_link {
            text-decoration: none;
            font-size: var(--textSize3);
            padding: var(--btnPaddingType3);
            border-radius: var(--borderRadiusType2);
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            text-align: center;
            /* background: linear-gradient(
            var(--colorPurpleLightType1),
            var(--colorPurpleLightType2)
          ); */
            background: var(--colorYellowLightType1);
            border: none;
            cursor: pointer;

            color: var(--colorWhite);

            &:hover {
              /* background: var(--bgGreyLight); */
              /* background: linear-gradient(var(--colorPurpleLightType1), var(--colorPurpleLightType2)); */
              background: var(--colorYellowLightType2);
              /* color: var(--textDark); */
            }
          }

          .start_consultation_btn {
            text-decoration: none;
            font-size: var(--textSize3);
            padding: var(--btnPaddingType3);
            border-radius: var(--borderRadiusType2);
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            gap: 0.5rem;
            text-align: center;
            background: linear-gradient(var(--bgGreen), var(--bgGreenLight));
            color: var(--bgWhite);
            font-weight: 600;
            cursor: pointer;

            &:hover {
              /* background: linear-gradient(var(--bgGreenLight), var(--bgGreen)); */
              background: var(--bgGreenLight);
              /* color: var(--bgWhite); */
            }
          }

          .end_consultation_btn {
            text-decoration: none;
            font-size: var(--textSize3);
            padding: var(--btnPaddingType3);
            border-radius: var(--borderRadiusType2);
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            gap: 0.5rem;
            text-align: center;
            background: linear-gradient(var(--bgRed), var(--bgRedLight));
            color: var(--bgWhite);
            font-weight: 600;
            cursor: pointer;

            &:hover {
              background: linear-gradient(var(--bgRedLight), var(--bgGreen));
              background: var(--bgRedLight);
              /* color: var(--bgWhite); */
            }
          }
        }
      }
    }
  }

  .table_controls_wrapper {
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    .search_input__ {
      width: 20%;
    }

    .row_action_grp {
      display: flex;
      align-items: center;
      gap: 1rem;

      .btns__ {
      }

      .primary_button {
        background: var(--backgroundGradientType1);
        color: var(--colorWhiteType1);

        &:hover {
          background: var(--backgroundGradientType1);
          color: var(--colorWhiteType1);
          opacity: 0.8;
        }
      }

      .secondary_button {
        border: var(--borderGreyType1);
      }
    }
  }
}

/*  tablet 481px — 768px: iPads, Tablets*/
@media only screen and (min-width: 481px) and (max-width: 768px) {
}

/* mobile 320px — 480px: Mobile devices*/
@media only screen and (max-width: 480px) {
  .DoctorsDashboard_wrapper {
    display: flex;
    height: 100vh;
    background: var(--colorWhite);
    /* background: var(--colorEmphasisType1); */

    overflow-y: hidden;

    .sidebar_wrapper {
      position: relative;
      width: 300px;
      /* border-right: var(--borderGreyLight); */

      .inner__ {
        /* position: sticky; */
        /* top: 70px; */
        display: grid;
        /* place-items: center; */
        gap: 1rem;
        padding: 1rem;
      }

      .box_1 {
        /* border: var(--borderGreyDark); */
        text-align: center;
        display: grid;
        place-items: center;
        gap: 0.25rem;
        /* border: var(--borderGreyLight); */
        border-radius: var(--borderRadiusType2);
        padding: var(--btnPaddingType1);
        /* background: var(--colorRedLightType1); */
        /* background: var(--colorEmphasisType2); */

        .image_box {
          width: 80px;
          height: 80px;
          position: relative;
          overflow: hidden;
          border-radius: 50%;

          img {
            width: 100%;
          }

          .change_avatar_btn {
            position: absolute;
            right: 0;
            bottom: 1%;
            z-index: 55555;
            background: var(--colorWhiteType1) !important;
            color: var(--colorYellowType1);
          }
        }

        h1 {
          margin: 0;
          font-weight: 500;
          text-transform: uppercase;
          font-size: var(--textSize3);
        }

        p {
          margin: 0;
          color: var(--bgGreyDark);
          font-size: var(--textSize4);
        }

        .nav_link {
          text-decoration: none;
          color: var(--textDark);
          font-size: var(--textSize3);
          padding: var(--btnPaddingType3);
          border-radius: var(--borderRadiusType2);
          display: flex;
          align-items: center;
          gap: 0.5rem;

          &:hover {
            background: var(--bgGreyLight);
          }
        }
      }

      .box_2 {
        text-align: center;
        border: var(--borderGreyLight);
        border-radius: var(--borderRadiusType2);
        padding: var(--btnPaddingType1);
        /* background: var(--colorBlueLightType1); */

        h3 {
          margin: 0;
          font-weight: 500;
          /* text-transform: uppercase; */
          font-size: var(--textSize3);
        }
        h1 {
          margin: 0;
          font-weight: 400;
          text-transform: uppercase;
          font-size: var(--textSize1);
        }
      }

      .box_3 {
        display: grid;
        border: var(--borderGreyLight);
        border-radius: var(--borderRadiusType2);
        padding: var(--btnPaddingType3);
        /* background: var(--colorYellowLightType1); */

        .nav_link {
          text-decoration: none;
          color: var(--textDark);
          /* color: var(--colorWhite); */
          font-size: var(--textSize3);
          padding: var(--btnPaddingType1);
          border-radius: var(--borderRadiusType2);
          display: flex;
          align-items: center;
          gap: 1rem;

          &:hover {
            color: var(--textDark);
            background: var(--bgGreyLight);
            /* background: var(--colorWhite); */
          }
        }
      }

      .box_4 {
        display: grid;
        border: var(--borderGreyLight);
        border-radius: var(--borderRadiusType2);
        padding: var(--btnPaddingType3);

        &:hover > img {
          filter: grayscale(0);
          scale: 1.2;
        }

        img {
          width: 80px;
          margin: auto;
          /* filter: grayscale(); */
          transition: all 0.3s ease-in-out;

          &:hover {
            filter: grayscale(0);
            scale: 1.2;
          }
        }

        .nav_link {
          text-decoration: none;
          color: var(--textDark);
          font-size: var(--textSize3);
          padding: var(--btnPaddingType1);
          border-radius: var(--borderRadiusType2);
          display: flex;
          align-items: center;
          gap: 1rem;

          &:hover {
            background: var(--bgGreyLight);
          }
        }
      }

      .box_5 {
        display: grid;
        border: var(--borderGreyLight);
        border-radius: var(--borderRadiusType2);
        padding: var(--btnPaddingType3);
        background: var(--colorRedLightType1);

        .logout_btn {
          text-decoration: none;
          color: var(--textDark);
          font-size: var(--textSize3);
          padding: var(--btnPaddingType1);
          border-radius: var(--borderRadiusType2);
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          background: var(--bgRed);
          color: var(--colorWhite);

          &:hover {
            background: var(--bgRedLight);
          }
        }
      }
    }

    .sidebar_wrapper_closed {
      width: 0px;
      transition: width 0.3s ease-in-out;
      opacity: 0;
      /* display: none; */
    }

    .sidebar_wrapper_opned {
      width: 300px;

      transition: width 0.3s ease-in-out;
    }

    .main_wrapper {
      z-index: 0;
      flex: 1;
      /* background: var(--bgGreyLight); */
      background: var(--colorWhite);
      position: relative;
      /* padding: 0 16px; */
      /* background: red; */
      height: 100vh;
      overflow-y: hidden;

      .fixed_bar {
        position: sticky;
        /* top: 0px; */
        display: grid;
        /* border-bottom: var(--borderGreyDark); */
        /* background: var(--bgGreyLight); */
        /* background: var(--colorWhite); */

        z-index: 999999;

        .row_1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: .5rem;
        }

        .row_2 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 0.5rem;

          h1,
          h3 {
            margin: 0;
            font-weight: 500;
            text-transform: uppercase;
            font-size: var(--textSize1);
            color: var(--colorBlueDarkType2);
          }

          h3 {
            margin: 0;
            font-weight: 500;
            text-transform: uppercase;
            font-size: var(--textSize2);
          }
        }
      }

      .movable_area {
        /* background: rgb(209, 227, 91); */
        height: 95vh;
        /* background: var(--bgGreyLight); */
        background: var(--bgGreyLight);

        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 5px;
          border-radius: 5px;
          background: none;
        }

        &::-webkit-scrollbar-track {
          /* background: #f1f1f1; */
          background: none;
          width: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
          background: none;
          width: 5px;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555;
          background: none;
          width: 5px;
        }
      }
    }

    .general_frame_wrapper {
      /* padding: 24px 0; */
      position: relative;

      .inner__ {
        display: grid;
        gap: 1rem;
        position: relative;
      }

      .heading_bar {
        position: sticky;
        top: 0px;
        transition: all 0.3s ease-in-out;
        display: grid;
        /* background: var(--bgGreyLight); */
        background: var(--colorWhite);
        color: var(--bgRed);
        z-index: 999999;
      }

      .heading_bar_2 {
        position: sticky;
        top: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* background: var(--bgGreyLight); */
        background: var(--colorWhite);
        z-index: 500;
        transition: all 0.3s ease-in-out;
        font-size: 14px;
        color: var(--bgRed);
      }

      .filter_bar {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        /* position: sticky;
      top: 100px; */
      }

      .grid_grp {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        gap: 1rem;
        padding: 0 1rem;
      }

      .grid_item {
        display: flex;
        border: var(--borderGrey);
        border-radius: var(--borderRadiusType1);
        background: var(--bgWhite);
        padding: var(--btnPaddingType3);
        align-items: center;
        justify-content: space-between;

        -webkit-box-shadow: 3px 3px 10px 3px #dddddd;
        -moz-box-shadow: 3px 3px 10px 3px #dddddd;
        box-shadow: 3px 3px 10px 3px #dddddd;

        .info_box {
          display: grid;
          gap: 0.5rem;
          /* flex: 1; */

          .date_time {
            /* display: grid; */
            display: flex;
            gap: 0.5rem;

            .date {
              font-size: var(--textSize4);
              background: var(--bgGreyLight);
              border-radius: var(--borderRadiusType2);
              padding: var(--btnPaddingType3);
              justify-self: flex-start;
            }
            .time {
              font-size: var(--textSize4);
              background: var(--bgGreyLight);
              border-radius: var(--borderRadiusType2);
              padding: var(--btnPaddingType3);
              justify-self: flex-start;
            }
          }

          .name {
            font-size: var(--textSize1);
            font-weight: 500;
          }

          .condition {
            /* font-weight: 500; */
            font-size: var(--textSize3);
            /* color: var(--textGrey); */
            /* background: #00ff6a; */
          }

          .controls_box {
          }
        }

        .image_box {
          border: var(--borderGreyLight);
          width: 150px;
          height: 150px;
          overflow: hidden;
          display: grid;
          /* place-items: center; */
          /* align-content: center; */
          place-content: center;
          border-radius: var(--borderRadiusType1);

          img {
            width: 100%;
          }
        }
      }

      .view_appointment_wrapper {
        display: flex;
        gap: 1rem;
        position: relative;

        .block_1 {
          width: 700px;
          /* border: var(--borderGrey); */
          /* border-radius: var(--borderRadiusType1); */
          /* background: var(--bgWhite); */
          padding: var(--btnPaddingType3);
          display: grid;
          gap: 2rem;
        }

        .block_child {
          border: var(--borderGrey);
          border-radius: var(--borderRadiusType1);
          padding: var(--btnPaddingType3);

          -webkit-box-shadow: 3px 3px 10px 3px #dddddd;
          -moz-box-shadow: 3px 3px 10px 3px #dddddd;
          box-shadow: 3px 3px 10px 3px #dddddd;
        }

        .block_head {
          font-weight: 600;
          margin-top: 0;
        }

        .patient_details_body {
          background: var(--bgWhite);
          border-radius: var(--borderRadiusType1);
          display: flex;

          .left_ {
            width: 250px;
            display: grid;
            place-content: center;
            text-align: center;
            padding: 1rem;
            gap: 0.5rem;

            .image_box {
              width: 150px;
              height: 150px;
              overflow: hidden;
              border-radius: 50%;
              display: grid;
              place-content: center;
              place-items: center;
              /* border: var(--borderGreyDark); */
              margin: auto;

              img {
                width: 100%;
              }
            }

            .name_box {
              margin: 0;
              font-weight: 500;
              text-transform: uppercase;
              font-size: var(--textSize2);
            }

            .specialization_box {
              margin: 0;
              color: var(--bgGreyDark);
              font-size: var(--textSize4);
            }

            .btn_grp {
              display: grid;
              gap: 0.5rem;
            }
            .nav_link {
              text-decoration: none;
              color: var(--textDark);
              font-size: var(--textSize3);
              padding: var(--btnPaddingType3);
              border-radius: var(--borderRadiusType2);
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 0.5rem;
              text-align: center;

              &:hover {
                background: var(--bgGreyLight);
              }
            }

            .start_consultation_btn {
              text-decoration: none;
              font-size: var(--textSize3);
              padding: var(--btnPaddingType3);
              border-radius: var(--borderRadiusType2);
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;
              gap: 0.5rem;
              text-align: center;
              background: linear-gradient(var(--bgGreen), var(--bgGreenLight));
              color: var(--bgWhite);
              font-weight: 600;
              cursor: pointer;

              &:hover {
                background: linear-gradient(
                  var(--bgGreenLight),
                  var(--bgGreen)
                );
                background: var(--bgGreenLight);
                /* color: var(--bgWhite); */
              }
            }

            .end_consultation_btn {
              text-decoration: none;
              font-size: var(--textSize3);
              padding: var(--btnPaddingType3);
              border-radius: var(--borderRadiusType2);
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;
              gap: 0.5rem;
              text-align: center;
              background: linear-gradient(var(--bgRed), var(--bgRedLight));
              color: var(--bgWhite);
              font-weight: 600;
              cursor: pointer;

              &:hover {
                background: linear-gradient(var(--bgRedLight), var(--bgGreen));
                background: var(--bgRedLight);
                /* color: var(--bgWhite); */
              }
            }
          }

          .right_ {
            flex: 1;
            border-left: var(--borderGrey);
          }

          .card_grp {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            gap: 1rem;
            padding: var(--btnPaddingType3);
            text-transform: capitalize;

            .card_item {
              .label {
                font-size: var(--textSize3);
                font-weight: 600;
              }
              .value {
                font-size: var(--textSize4);
                margin-top: 0.5rem;
              }
            }
          }
        }

        .patient_notes_body {
          background: var(--bgWhite);
          border-radius: var(--borderRadiusType1);
          padding: var(--btnPaddingType3);
          background: white;
        }

        .patient_submitted_files_body {
          .card_grp {
            display: grid;
            gap: 1rem;

            .card_item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              background: var(--bgWhite);
              border-radius: var(--borderRadiusType1);
              padding: var(--btnPaddingType1);

              .label {
                width: 80%;
              }

              .download {
              }
            }
          }
        }

        .drug_prescriptions {
          display: grid;
          gap: 1rem;
        }

        .drug_prescription_body {
          .card_grp {
            display: grid;
            gap: 1rem;

            .card_item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              background: var(--bgWhite);
              border-radius: var(--borderRadiusType1);
              padding: var(--btnPaddingType1);

              .label {
                width: 80%;
              }

              .download {
              }
            }
          }
        }

        .add_prescription_container {
          display: grid;
          place-content: center;
        }

        .block_2 {
          flex: 1;
          border: var(--borderGrey);
          border-radius: var(--borderRadiusType1);
          /* background: var(--bgWhite); */
          padding: var(--btnPaddingType3);
          position: relative;
          /* align-self: flex-start; */
          /* height: 70vh; */

          .sticky_inner__ {
            position: sticky;
            top: 100px;
            /* background: red; */
          }

          .btn_grp {
            display: grid;
            gap: 0.5rem;
            /* position: sticky;
          top: 150px; */
          }

          .nav_link {
            text-decoration: none;
            font-size: var(--textSize3);
            padding: var(--btnPaddingType3);
            border-radius: var(--borderRadiusType2);
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            text-align: center;
            /* background: linear-gradient(
            var(--colorPurpleLightType1),
            var(--colorPurpleLightType2)
          ); */
            background: var(--colorYellowLightType1);
            border: none;
            cursor: pointer;

            color: var(--colorWhite);

            &:hover {
              /* background: var(--bgGreyLight); */
              /* background: linear-gradient(var(--colorPurpleLightType1), var(--colorPurpleLightType2)); */
              background: var(--colorYellowLightType2);
              /* color: var(--textDark); */
            }
          }

          .start_consultation_btn {
            text-decoration: none;
            font-size: var(--textSize3);
            padding: var(--btnPaddingType3);
            border-radius: var(--borderRadiusType2);
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            gap: 0.5rem;
            text-align: center;
            background: linear-gradient(var(--bgGreen), var(--bgGreenLight));
            color: var(--bgWhite);
            font-weight: 600;
            cursor: pointer;

            &:hover {
              /* background: linear-gradient(var(--bgGreenLight), var(--bgGreen)); */
              background: var(--bgGreenLight);
              /* color: var(--bgWhite); */
            }
          }

          .end_consultation_btn {
            text-decoration: none;
            font-size: var(--textSize3);
            padding: var(--btnPaddingType3);
            border-radius: var(--borderRadiusType2);
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            gap: 0.5rem;
            text-align: center;
            background: linear-gradient(var(--bgRed), var(--bgRedLight));
            color: var(--bgWhite);
            font-weight: 600;
            cursor: pointer;

            &:hover {
              background: linear-gradient(var(--bgRedLight), var(--bgGreen));
              background: var(--bgRedLight);
              /* color: var(--bgWhite); */
            }
          }
        }
      }
    }
  }

  .table_controls_wrapper {
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    .search_input__ {
      width: 20%;
    }

    .row_action_grp {
      display: flex;
      align-items: center;
      gap: 1rem;

      .btns__ {
      }

      .primary_button {
        background: var(--backgroundGradientType1);
        color: var(--colorWhiteType1);

        &:hover {
          background: var(--backgroundGradientType1);
          color: var(--colorWhiteType1);
          opacity: 0.8;
        }
      }

      .secondary_button {
        border: var(--borderGreyType1);
      }
    }
  }
}
