/* desktop 769px — 1024px: Small screens, laptops */
@media only screen and (min-width: 769px) {
  .login_wrapper {
    width: 100%;
    height: 100vh;
    display: grid;

    .inner__ {
      width: 80%;
      margin: auto;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      background: var(--bgGreyLight);
      padding: var(--paddingType1);
      border-radius: var(--borderRadiusType1);
    }

    .banner_container__ {
      /* max-height: 80%; */
      display: grid;
      place-content: center;

      .title__ {
        font-size: var(--textSize1);
        text-align: center;
        font-weight: 500;

        background-image: linear-gradient(
          to right,
          var(--colorRedDarkType1),
          blue
        );
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }

      .image_box {
        overflow: hidden;
        border-radius: var(--borderRadiusType1);
        display: grid;

        img {
          margin: auto;
          width: 80%;
          margin: auto;
          /* height: 50%; */
        }
      }
    }

    .form_container__ {
      padding: var(--paddingType1);
      border-left: var(--borderGreyDark);
      /* place-content: center; */

      .form_grp__ {
        width: 80%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: ceter;
      }

      .title__ {
        font-size: var(--textSize1);
        text-align: center;
        padding: var(--paddingType1);
      }

      .sub_text_1,
      .sub_text {
        a {
          text-decoration: none;
          color: var(--colorRedDarkType1);
        }
      }

      .sub_text_1 {
        text-align: left;
      }
      .sub_text {
        text-align: center;
      }
    }
  }
}

/*  tablet 481px — 768px: iPads, Tablets*/
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .login_wrapper {
    width: 100%;
    height: 100vh;
    display: grid;

    .inner__ {
      width: 80%;
      margin: auto;
      display: grid;
      /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
      background: var(--bgGreyLight);
      padding: var(--paddingType1);
      border-radius: var(--borderRadiusType1);
    }

    .banner_container__ {
      /* max-height: 80%; */
      display: grid;
      place-content: center;

      .title__ {
        font-size: var(--textSize2);
        text-align: center;
        font-weight: 500;

        background-image: linear-gradient(
          to right,
          var(--colorRedDarkType1),
          blue
        );
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }

      .image_box {
        overflow: hidden;
        border-radius: var(--borderRadiusType1);
        display: grid;

        img {
          margin: auto;
          width: 20%;
          margin: auto;
          /* height: 50%; */
        }
      }
    }

    .form_container__ {
      /* border-left: var(--borderGreyDark); */
      /* place-content: center; */
      padding: var(--paddingType2);

      .form_grp__ {
        /* width: 80%; */
        margin: auto;
        /* display: flex; */
        /* flex-direction: column; */
        /* justify-content: ceter; */
      }

      .title__ {
        font-size: var(--textSize1);
        text-align: center;
        padding: var(--paddingType1);
        font-weight: 700;
        color: var(--textDark);
      }

      .sub_text_1,
      .sub_text {
        a {
          text-decoration: none;
          color: var(--colorRedDarkType1);
        }
      }

      .sub_text_1 {
        text-align: left;
      }
      .sub_text {
        text-align: center;
      }
    }
  }
}

/* mobile 320px — 480px: Mobile devices*/
@media only screen and (max-width: 480px) {
  .login_wrapper {
    width: 100%;
    height: 100vh;
    display: grid;

    .inner__ {
      width: 80%;
      margin: auto;
      display: grid;
      /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
      background: var(--bgGreyLight);
      padding: var(--paddingType1);
      border-radius: var(--borderRadiusType1);
    }

    .banner_container__ {
      /* max-height: 80%; */
      display: grid;
      place-content: center;

      .title__ {
        font-size: var(--textSize2);
        text-align: center;
        font-weight: 500;

        background-image: linear-gradient(
          to right,
          var(--colorRedDarkType1),
          blue
        );
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }

      .image_box {
        overflow: hidden;
        border-radius: var(--borderRadiusType1);
        display: grid;
      }

      .image_box img {
        margin: auto;
        width: 20%;
      }
    }

    .form_container__ {
      /* border-left: var(--borderGreyDark); */
      /* place-content: center; */
      padding: var(--paddingType2);

      .form_grp__ {
        /* width: 80%; */
        margin: auto;
        /* display: flex; */
        /* flex-direction: column; */
        /* justify-content: ceter; */
      }

      .title__ {
        font-size: var(--textSize1);
        text-align: center;
        padding: var(--paddingType1);
        font-weight: 700;
        color: var(--textDark);
      }

      .sub_text_1,
      .sub_text {
        a {
          text-decoration: none;
          color: var(--colorRedDarkType1);
        }
      }

      .sub_text_1 {
        text-align: left;
      }
      .sub_text {
        text-align: center;
      }
    }
  }
}
