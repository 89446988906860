.loading_wrapper__ {
  position: absolute;
  display: grid;
  place-content: center;
  height: 100vh;
  width: 100%;
  top: 0;
  background: #f0fff34a;
  backdrop-filter: blur(5px);
  z-index: 999999;
  /* left: 50%; */
  /* right: 50%; */

  .lds-ripple,
  .lds-ripple div {
    box-sizing: border-box;
  }
  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    scale: 4.5;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid var(--colorGreenType5);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
    border: 4px solid var(--colorGreenType2);
  }
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    opacity: 0;
  }
}
