/* desktop 769px — 1024px: Small screens, laptops */
@media only screen and (min-width: 769px) {
  .SignUp_wrapper {
    display: flex;
    /* height: 80vh; */
    position: relative;
    width: 80%;
    margin: 2rem auto;
    /* border: 0.5px solid var(--bgGreyDark); */
    border-radius: 10px;
    align-items: flex-start;
    flex-wrap: wrap;

    .steps_container {
      /* border: 0.5px solid grey; */
      width: 400px;
      min-width: 400px;
      background: var(--bgGreyLight);
      /* display: grid; */
      gap: 2rem;
      padding: 2rem 0;
      position: sticky;
      top: 5%;
      border-radius: 10px;

      .inner_1 {
        display: flex;
        padding: 1rem;
        display: none;

        img {
          width: 50px;
          margin: auto;
        }
      }

      .inner_2 {
        width: 80%;
        margin: auto;
        display: grid;
        gap: 4rem;
        font-size: 14px;
        padding: 1rem;
        position: relative;

        .line__ {
          position: absolute;
          width: 2px;
          /* height: 70%; */
          top: 10%;
          bottom: 10%;
          left: 9%;
          /* right: -50%; */
          background: #444;
          opacity: 0.2;
          display: none;
        }

        .steps_grp {
          display: flex;
          gap: 1rem;
          opacity: 0.4;
          cursor: no-drop;

          .icon_box {
            font-size: 1.2rem;
            align-self: flex-start;
            z-index: 25;
            background: white;
            padding: 0.5rem;
            border-radius: 10px;
          }

          .label_grp {
            text-transform: capitalize;
            display: grid;
            gap: 0.2rem;

            .big_text {
              font-weight: 600;
            }
            .small_text {
              color: #666;
            }
          }
        }

        .steps_grp_current {
          display: flex;
          gap: 1rem;

          .icon_box {
            font-size: 1.2rem;
            background: white;
            padding: 0.5rem;
            align-self: flex-start;
            border-radius: 10%;
            z-index: 25;
          }

          .label_grp {
            text-transform: capitalize;
            display: grid;
            gap: 0.2rem;

            .big_text {
              font-weight: 600;
            }
            .small_text {
              color: #666;
            }
          }
        }
      }
    }

    .form_container {
      flex: 1;
      min-width: 400px;

      .inner_form_container {
        width: 80%;
        margin: auto;
        /* border: 0.5px solid; */
        padding: 2rem 0;

        .btn_grp {
          display: flex;
          align-items: center;
          gap: 1rem;
          justify-content: center;
          width: 100%;
        }

        .sub_text {
          a {
            text-decoration: none;
            color: var(--colorRedDarkType1);
            text-align: center;
          }
        }

        .details_wrapper {
          .row_1 {
            text-align: center;
          }
          .subtext {
            text-align: center;
            /* text-transform: capitalize; */
          }

          .form_grp {
            display: grid;

            .grid_x2 {
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
              gap: 1rem;
            }

            /* button {
            justify-self: flex-end !important;
          } */
          }
        }

        .otp_wrapper {
          .row_1 {
            text-align: center;
          }
          .subtext {
            text-align: center;
            /* text-transform: capitalize; */
          }

          .form_grp {
            display: grid;
            place-content: center;
            width: 100%;

            /* button {
            justify-self: center !important;
          } */
          }
        }
      }
    }
  }
}

/*  tablet 481px — 768px: iPads, Tablets*/
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .SignUp_wrapper {
    /* display: flex; */
    gap: 1rem;
    position: relative;
    border-radius: 10px;
    align-items: flex-start;
    flex-wrap: wrap;
    background: white;
  }

  .steps_container {
    background: var(--bgGreyLight);
    gap: 2rem;
    padding: 2rem 0;
  }

  .inner_1 {
    display: flex;
    display: none;
  }

  .inner_1 img {
    width: 50px;
    margin: auto;
  }

  .inner_2 {
    margin: auto;
    display: grid;
    gap: 4rem;
    font-size: 14px;
    padding: 1rem;
    position: relative;
  }

  .line__ {
    position: absolute;
    width: 2px;
    top: 10%;
    bottom: 10%;
    left: 9%;
    background: #444;
    opacity: 0.2;
    display: none;
  }

  .steps_grp {
    display: flex;
    gap: 1rem;
    opacity: 0.4;
    cursor: no-drop;
  }

  .icon_box {
    font-size: 1.2rem;
    align-self: flex-start;
    z-index: 25;
    background: white;
    padding: 0.5rem;
    border-radius: 10px;
  }

  .label_grp {
    text-transform: capitalize;
    display: grid;
    gap: 0.2rem;
  }

  .big_text {
    font-weight: 600;
  }

  .small_text {
    color: #666;
  }

  .steps_grp_current {
    display: flex;
    gap: 1rem;
  }

  .steps_grp_current .icon_box {
    font-size: 1.2rem;
    background: white;
    padding: 0.5rem;
    align-self: flex-start;
    border-radius: 10%;
    z-index: 25;
  }

  .steps_grp_current .label_grp {
    text-transform: capitalize;
    display: grid;
    gap: 0.2rem;
  }

  .steps_grp_current .big_text {
    font-weight: 600;
  }

  .steps_grp_current .small_text {
    color: #666;
  }

  .form_container {
    flex: 1;
    background: white;
    width: 100%;
  }

  .inner_form_container {
    margin: auto;
    padding: 2rem 1rem;
  }

  .btn_grp {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    width: 100%;
  }

  .sub_text a {
    text-decoration: none;
    color: var(--colorRedDarkType1);
    text-align: center;
  }

  .details_wrapper .row_1,
  .details_wrapper .subtext,
  .otp_wrapper .row_1,
  .otp_wrapper .subtext {
    text-align: center;
  }

  .form_grp {
    display: grid;
  }

  .otp_wrapper .form_grp {
    place-content: center;
    width: 100%;
  }
}

/* mobile 320px — 480px: Mobile devices*/
@media only screen and (max-width: 480px) {
  .SignUp_wrapper {
    display: flex;
    gap: 1rem;
    position: relative;
    border-radius: 10px;
    align-items: flex-start;
    flex-wrap: wrap;
    background: white;
  }

  .steps_container {
    background: var(--bgGreyLight);
    gap: 2rem;
    padding: 2rem 0;
  }

  .inner_1 {
    display: flex;
    display: none;
  }

  .inner_1 img {
    width: 50px;
    margin: auto;
  }

  .inner_2 {
    margin: auto;
    display: grid;
    gap: 4rem;
    font-size: 14px;
    padding: 1rem;
    position: relative;
  }

  .line__ {
    position: absolute;
    width: 2px;
    top: 10%;
    bottom: 10%;
    left: 9%;
    background: #444;
    opacity: 0.2;
    display: none;
  }

  .steps_grp {
    display: flex;
    gap: 1rem;
    opacity: 0.4;
    cursor: no-drop;
  }

  .icon_box {
    font-size: 1.2rem;
    align-self: flex-start;
    z-index: 25;
    background: white;
    padding: 0.5rem;
    border-radius: 10px;
  }

  .label_grp {
    text-transform: capitalize;
    display: grid;
    gap: 0.2rem;
  }

  .big_text {
    font-weight: 600;
  }

  .small_text {
    color: #666;
  }

  .steps_grp_current {
    display: flex;
    gap: 1rem;
  }

  .steps_grp_current .icon_box {
    font-size: 1.2rem;
    background: white;
    padding: 0.5rem;
    align-self: flex-start;
    border-radius: 10%;
    z-index: 25;
  }

  .steps_grp_current .label_grp {
    text-transform: capitalize;
    display: grid;
    gap: 0.2rem;
  }

  .steps_grp_current .big_text {
    font-weight: 600;
  }

  .steps_grp_current .small_text {
    color: #666;
  }

  .form_container {
    flex: 1;
    background: white;
    width: 100%;
  }

  .inner_form_container {
    margin: auto;
    padding: 2rem 1rem;
  }

  .btn_grp {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    width: 100%;
  }

  .sub_text a {
    text-decoration: none;
    color: var(--colorRedDarkType1);
    text-align: center;
  }

  .details_wrapper .row_1,
  .details_wrapper .subtext,
  .otp_wrapper .row_1,
  .otp_wrapper .subtext {
    text-align: center;
  }

  .form_grp {
    display: grid;
  }

  .otp_wrapper .form_grp {
    place-content: center;
    width: 100%;
  }
}
