:root {
  --bgGreyLight: #f5f5f5;
  --bgGreyDark: #a5a5a5;
  --bgGreen: #107537;
  --bgGreenLight: #17a34c;
  --bgRed: #a31717;
  --bgRedLight: #d82727;
  --bgWhite: #fff;
  
  --colorGreyType1: #ececec;
  --colorGreyType2: #dddddd;

  --colorRedDarkType1: #a31717;
  --colorRedDarkType2: #4e0b0b;
  --colorRedLightType1: #fdf0f0;

  --colorBlueDarkType1: #2f6fb4;
  --colorBlueDarkType2: #245a93;
  --colorBlueDarkType3: #d3e5f7;
  --colorBlueLightType1: #eaf4ff;
  /*  */
  --colorGreenLightType1: #dffcea;
  /*  */
  --colorPurpleLightType1: #c039a3;
  --colorPurpleLightType2: #9e2f86;

  --colorOrangeType1: #e77325;

  --colorYellowType1: #f7fa3c;
  /*  */
  --colorYellowLightType1: #707880;
  --colorYellowLightType2: #555a60;
  /*  */
  --colorWhite: #fff;

  --colorEmphasisType1: #404258;
  --colorEmphasisType2: #474e68;
  --colorEmphasisType3: #50577a;
  --colorEmphasisType4: #6b728e;

  --textDark: #444;
  --textGrey: #a5a5a5;

  --textSize1: 1.6rem;
  --textSize2: 1.2rem;
  --textSize3: 1rem;
  --textSize4: 0.8rem;
  --textSize5: 2.5rem;

  --borderRedLight: 3px solid var(--colorRedDarkType1);
  --borderBlueLight: 1px solid var(--colorBlueDarkType3);
  --borderGreyLight: 1px solid var(--bgGreyLight);
  --borderGreyDark: 1px solid var(--bgGreyDark);

  --borderRadiusType1: 10px;
  --borderRadiusType2: 5px;

  --paddingType1: 1rem;
  --paddingType2: 1rem 0.5rem;

  --btnPaddingType1: 12px 18px;
  --btnPaddingType2: 12px 24px;
  --btnPaddingType3: 12px;
  --btnPaddingType4: 12px 0;

  /* end of variables */
}

/* check */
/* check */
/* check */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
